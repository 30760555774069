<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style >
*{
  margin: 0;
  padding: 0;
}
#app {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  font-size: 12px;
}

</style>

<template>
  <div class="home">
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
  },
  data() {
    return{}
   
  },
  methods: {
   
  }
}
</script>

<style  scoped>
.home {
  width: 100%;
  height: 100%;
}
</style>
